<template>
	<section id="intro" class="main-content" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="row">
			<slot name="content">
				<div class="columns column6">
					<h4 class="subtitle">
						<span>{{ subtitle }}</span>
					</h4>
					<h1>
						<span>{{ title }}</span>
						<span>{{ titleRight }}</span>
					</h1>
					<div v-parse-links v-html="content" />
				</div>
			</slot>
			<slot name="rightContent">
				<slot name="images" />
				<div v-if="introImage" class="columns column6 intro-images">
					<div v-parallax="{ speed: -0.025 }" class="intro-square">
						<picture>
							<source v-if="introImageWebp" :srcset="introImageWebp" type="image/webp" />
							<source :srcset="introImage" />
							<img :src="introImage" loading="lazy" :alt="introImageAlt" />
						</picture>
					</div>
					<div v-if="introImagePortrait" v-parallax="{ speed: 0.025 }" class="intro-portrait">
						<picture>
							<source v-if="introImagePortraitWebp" :srcset="introImagePortraitWebp" type="image/webp" />
							<source :srcset="introImagePortrait" />
							<img :src="introImagePortrait" loading="lazy" :alt="introImagePortraitAlt" />
						</picture>
					</div>
				</div>
			</slot>
		</div>
		<slot name="extraContent" />
	</section>
</template>

<script setup>
const route = useRoute();

defineProps({
	title: { type: String, default: '' },
	titleRight: { type: String, default: '' },
	subtitle: { type: String, default: '' },
	content: { type: String, default: '' },
	bookingId: { type: String, default: '' },
	introImage: { type: String, default: '' },
	introImageAlt: { type: String, default: '' },
	introImageWebp: { type: String, default: '' },
	introImagePortrait: { type: String, default: '' },
	introImagePortraitAlt: { type: String, default: '' },
	introImagePortraitWebp: { type: String, default: '' },
});
</script>

<style lang="scss" scoped>
#intro {
	overflow: visible;

	.row {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
		overflow: visible;
	}

	h1 {
		margin: 0;
		max-width: 600px;

		span {
			width: 100%;
			display: block;

			&:last-child {
				text-align: right;
			}
		}
	}

	p,
	:deep(p) {
		max-width: 480px;
	}
}

.intro-images {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-end;

	div {
		width: 48%;

		&:last-child {
			margin-bottom: 90px;
		}
	}
}

@media (max-width: 960px) {
	.column6 {
		padding: 5px 25px;
	}

	.intro-images {
		div {
			width: 47%;

			&:last-child {
				margin-bottom: 30px;
			}
		}
	}
}
</style>
